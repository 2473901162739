import { createSlice } from "@reduxjs/toolkit";

const calendarSlice = createSlice({
  name: "calendar",
  initialState: {
    calendarData: null,
  },
  reducers: {
    setCalendarData: (state, action) => {
      state.calendarData = action.payload;
    },
  },
});

export const { setCalendarData, removeCalendarData } = calendarSlice.actions;

export const selectCalendarData = (state) => state.calendar.calendarData;

export default calendarSlice.reducer;
