import { Box, Tooltip, tooltipClasses, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const CustomTooltip = styled(({ className, open, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    // placement="bottom"
    open={open}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: { color: "white" },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    boxShadow: theme.shadows[5],
    borderRadius: 8,
  },
}));

//Formatting currency
export const formatCurrency = (value) =>
  value
    ? value.toLocaleString("en-IN", { style: "currency", currency: "INR" })
    : "-";

//Footer totals
export const renderFooterTotal = (table, fieldName) => {
  const total =
    parseFloat(table.getRowModel().rows[0]?.original?.[fieldName]) || 0;
  return total ? formatCurrency(total) : "";
};

//Currency fields
export const currencyCell = ({ cell }) =>
  formatCurrency(parseFloat(cell.getValue()));

// Common Currency Column Config
export const currencyColumnConfig = {
  size: 50,
  Cell: currencyCell,
  muiTableHeadCellProps: {
    align: "right",
  },
  muiTableBodyCellProps: {
    align: "right",
  },
  muiTableFooterCellProps: {
    align: "right",
  },
};

// RoomNumberCell Tooltip
export const RoomNumberCell = ({ cell }) => {
  const { t } = useTranslation();
  const roomNumbers = cell.getValue();
  const roomArray = roomNumbers.split(",");
  const firstRoom = roomArray[0];
  const isMultipleRooms = roomArray.length > 1;
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleTooltipToggle = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography
        sx={{
          fontSize: { xs: "2.564vw", sm: "1.2vw", md: "0.695vw" },
          fontWeight: 400,
        }}
      >
        {isMultipleRooms ? "" : firstRoom}
      </Typography>

      {isMultipleRooms && (
        <CustomTooltip
          title={
            <>
              <Typography
                sx={{
                  fontSize: { xs: "2.564vw", sm: "1.439vw", md: "0.834vw" },
                  color: "#4F4F4F",
                }}
              >
                {t("pages.reports.total_room")} -&nbsp;
                <span style={{ color: "#DEA829" }}>
                  {roomArray.length.toString().padStart(2, "0")}
                </span>
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "2.564vw", sm: "1.439vw", md: "0.834vw" },
                  color: "#474747",
                }}
              >
                {roomNumbers}
              </Typography>
            </>
          }
          open={tooltipOpen}
        >
          <Box
            sx={{
              position: "relative",
              cursor: "pointer",
              width: { xs: "7.597vw", sm: "3.597vw", md: "2.083vw" },
              height: { xs: "7.597vw", sm: "3.597vw", md: "2.083vw" },
              border: isMultipleRooms ? "1px solid rgba(222, 168, 41, 1)" : "",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
            }}
            onClick={handleTooltipToggle}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={handleTooltipClose}
          >
            <Typography
              sx={{
                fontSize: { xs: "2.564vw", sm: "1.2vw", md: "0.695vw" },
                fontWeight: 400,
              }}
            >
              {firstRoom}
            </Typography>
            {isMultipleRooms && (
              <Box
                sx={{
                  position: "absolute",
                  top: { xs: "-7%", sm: "-9%" },
                  right: { xs: "1%", sm: "-10%" },
                  height: { xs: "2vw", sm: "1.199vw", md: "0.694vw" },
                  width: { xs: "2vw", sm: "1.199vw", md: "0.694vw" },
                  backgroundColor: "rgba(217, 166, 46, 1)",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "1.5vw", sm: "0.839vw", md: "0.486vw" },
                    color: "#FFFFFF",
                  }}
                >
                  {roomArray.length}
                </Typography>
              </Box>
            )}
          </Box>
        </CustomTooltip>
      )}
    </Box>
  );
};
// Assign Type
export const AssignType = ({ cell }) => {
  const { t } = useTranslation();

  const status = t(
    `pages.reports.housekeeping.status_type.${cell.getValue()}`.toLowerCase()
  );

  const widthByStatus = {
    [t("pages.reports.housekeeping.status_type.cleaned") || "cleaned"]: "5vw",
    [t("pages.reports.housekeeping.status_type.assigned") || "assigned"]: "6vw",
    [t("pages.reports.housekeeping.status_type.dirty") || "dirty"]: "4vw",
  };
  return (
    <Box
      sx={{
        position: "relative",
        left: "-10px",
        cursor: "pointer",
        width: widthByStatus[status] || "3vw",
        height: { sm: "3.597vw", md: "2.083vw" },
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor:
          status === t("pages.reports.housekeeping.status_type.clean")
            ? "#C4FFB9"
            : status === t("pages.reports.housekeeping.status_type.assigned")
            ? "#B6D4FD"
            : status === t("pages.reports.housekeeping.status_type.dirty")
            ? "#FFEDD1"
            : "transparent",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Inter, sans-serif",
          fontSize: { xs: "1.199vw", sm: "1.199vw", md: "0.694vw" },
          fontWeight: 500,
          color: "#252525",
        }}
      >
        {t(
          `pages.reports.housekeeping.status_type.${cell.getValue()}`.toLowerCase()
        )}
      </Typography>
    </Box>
  );
};
