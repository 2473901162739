import GuestHivesLogo from "../assets/icons/guesthives_logo.png";
import VacantIcon from "../assets/icons/vacant-icon.svg";
import OccupiedIcon from "../assets/icons/occupied-icon.svg";
import ReservedIcon from "../assets/icons/reserved-icon.svg";
import ArrivalIcon from "../assets/icons/arrival-icon.svg";
import DepartureIcon from "../assets/icons/departure-icon.svg";
import CleanRoomsIcon from "../assets/icons/clean-rooms-icon.svg";
import CleaningPurple from "../assets/icons/cleaning_purple.svg";
import UnCleanRoomsIcon from "../assets/icons/unclean-rooms-icon.svg";
import ReloadIcon from "../assets/icons/Reload-icon.svg";
import ShrinkIcon from "../assets/icons/shrink-icon.svg";
import ExpectedArrivalActive from "../assets/icons/Expected-Arrival-Active-Icon.svg";
import ExpectedArrivalInActive from "../assets/icons/Expected-Arrival-InActive-Icon.svg";
import ExpectedDepartureActive from "../assets/icons/Expected-Departure-Active-Icon.svg";
import ExpectedDepartureInActive from "../assets/icons/Expected-Departure-InActive-Icon.svg";
import PaymentDueInActive from "../assets/icons/Payment-Due-InActive-Icon.svg";
import PaymentDueActive from "../assets/icons/group_payment_white.svg";
import ThreeDots from "../assets/icons/three-dots-icon.svg";
import OrangeThreeDots from "../assets/icons/orange-three-dots-icon.svg";
import VerticalThreeDots from "../assets/icons/vertical-three-dots.svg";
import Reserve from "../assets/icons/reserve-icon.svg";
import VacantDoor from "../assets/icons/vacant-door.svg";
import ReservedDoor from "../assets/icons/reserved-door-icon.svg";
import CheckIn from "../assets/icons/check_in.svg";
import OccupiedDoor from "../assets/icons/occupied-door-icon.svg";
import CheckOut from "../assets/icons/check-out.svg";
import PersonIcon from "../assets/icons/Person-icon.svg";
import SampleAadhar from "../assets/icons/sample-aadhar-icon.svg";
import SampleDrivingLicense from "../assets/icons/sample-driving-license-icon.svg";
import EditIcon from "../assets/icons/edit-icon.svg";
import DeleteIcon from "../assets/icons/delete_icon.svg";
import ImageEditIcon from "../assets/icons/Image-Edit-Icon.svg";
import ToastSuccessIcon from "../assets/icons/Employee-Add-Toast.svg";
import DeleteToast from "../assets/icons/Employee-Delete-Toast.svg";
import RoomViewIcon from "../assets/icons/room-view-icon.svg";
import FloorViewIcon from "../assets/icons/floor-view-icon.svg";
import CalendarViewIcon from "../assets/icons/calendar-view-icon.svg";
import HighlightedRoomViewIcon from "../assets/icons/highlighted_room_view.svg";
import HighlightedFloorViewIcon from "../assets/icons/highlighted_floor_view.svg";
import HighlightedCalendarViewIcon from "../assets/icons/highlighted_calendar_view.svg";
import AssignTask from "../assets/icons/assign_task.svg";
import ExtendStay from "../assets/icons/gi_extend.svg";
import Reset from "../assets/icons/guest_info_reset.svg";
import VacantGreen from "../assets/icons/vacant_green.svg";
import OccupiedRed from "../assets/icons/occupied_red.svg";
import FloorView from "../assets/icons/floor_view_yellow.svg";
import FloorViewWhite from "../assets/icons/floor_view_white.svg";
import RoomView from "../assets/icons/room_view_yellow.svg";
import PencilEdit from "../assets/icons/pencil_icon.svg";
import PencilWhite from "../assets/icons/pencil_white.svg";
import ToastWarnIcon from "../assets/icons/warn.svg";
import ToastDeleteIcon from "../assets/icons/delete_red.svg";
import ToastErrorIcon from "../assets/icons/error.svg";
import MaintenanceIcon from "../assets/icons/maintenance.svg";
import CleanedBlueIcon from "../assets/icons/cleaned_blue.svg";
import AssignedBlueIcon from "../assets/icons/assigned_blue.svg";
import DirtyBlueIcon from "../assets/icons/dirty_blue.svg";
import VacantFilter from "../assets/icons/vacant_filter.svg";
import OccupiedFilter from "../assets/icons/occupied_filter.svg";
import ReservedFilter from "../assets/icons/reserved_filter.svg";
import VacantFilterFilled from "../assets/icons/vacant_filter_fill.svg";
import OccupiedFilterFilled from "../assets/icons/occupied_filter_fill.svg";
import ReservedFilterFilled from "../assets/icons/reserved_filter_fill.svg";
import ExpandIcon from "../assets/icons/expand_filter.svg";
import SuccessToaster from "../assets/icons/success_toaster.svg";
import Arrival from "../assets/icons/arrival_icon.svg";
import Departure from "../assets/icons/departure_icon.svg";
import SearchIcon from "../assets/icons/search.svg";
import Maintenance from "../assets/icons/maintenance_icon.svg";
import MaintenanceGrey from "../assets/icons/maintenance_grey.svg";
import ReserveWhite from "../assets/icons/reserve_white.svg";
import ExpandGrey from "../assets/icons/expand_grey.svg";
import VacantWhite from "../assets/icons/vacant_white.svg";
import AllocatedWhite from "../assets/icons/allocated_white.svg";
import MaintenanaceWhite from "../assets/icons/maintenanace_white.svg";
import CleaningGreen from "../assets/icons/cleaning_green.svg";
import CleaningRed from "../assets/icons/cleaning_red.svg";
import CleaningGold from "../assets/icons/cleaning_gold.svg";
import CleaningWhite from "../assets/icons/cleaning_white.svg";
import UncleanedGreen from "../assets/icons/uncleaned_green.svg";
import UncleanedPurple from "../assets/icons/uncleaned_purple.svg";
import UncleanedGold from "../assets/icons/uncleaned_gold.svg";
import UncleanedWhite from "../assets/icons/uncleaned_white.svg";
import CleanedRed from "../assets/icons/cleaned_red.svg";
import CleanedPurple from "../assets/icons/cleaned_purple.svg";
import CleanedGold from "../assets/icons/cleaned_gold.svg";
import CleanedWhite from "../assets/icons/cleaned_white.svg";
import ExpecArrivalRed from "../assets/icons/expec_arrival_red.svg";
import ExpecArrivalYellow from "../assets/icons/expec_arrival_yellow.svg";
import ExpecDepartYellow from "../assets/icons/expec_depart_yellow.svg";
import ExpecDepartRed from "../assets/icons/expec_depart_red.svg";
import ExpecDepartGreen from "../assets/icons/expec_depart_green.svg";
import AllocatedViolet from "../assets/icons/allocated_violet.svg";
import Adult from "../assets/icons/adult.svg";
import AdultBlack from "../assets/icons/adult_black.svg";
import ChildrenBlack from "../assets/icons/child_black.svg";
import Children from "../assets/icons/children.svg";
import ExpandButton from "../assets/icons/expand_button.svg";
import ExpandRevert from "../assets/icons/expand_revert.svg";
import ResetButton from "../assets/icons/reset_button.svg";
import ArrivalWhite from "../assets/icons/arrival_white.svg";
import DepartureWhite from "../assets/icons/departure_white.svg";
import TabExpand from "../assets/icons/tab_expand.svg";
import TabShrink from "../assets/icons/tab_shrink.svg";
import Shrink from "../assets/icons/shrink.svg";
import Print from "../assets/icons/print.svg";
import Ac from "../assets/icons/vacant_room.svg";
import NonAc from "../assets/icons/occupied_room.svg";
import SingleBedIcon from "../assets/icons/single_bed.svg";
import DoubleBedIcon from "../assets/icons/double_bed.svg";
import RSEditIcon from "../assets/icons/rs_edit.svg";
import RSDeleteIcon from "../assets/icons/rs_delete.svg";
import EditIconYellow from "../assets/icons/edit_yellow_icon.svg";
import DeleteIconWhite from "../assets/icons/delete_white_icon.svg";
import Edit from "../assets/icons/gi_edit.svg";
import View from "../assets/icons/gi_view.svg";
import Cancel from "../assets/icons/cancel.svg";
import ExtendWhite from "../assets/icons/extend_white.svg";
import CancelWhite from "../assets/icons/cancel_white.svg";
import PrintYellow from "../assets/icons/gi_print_yellow.svg";
import GIDelete from "../assets/icons/dep_delete.svg";
import RoomShift from "../assets/icons/gi_shift.svg";
import EditWhite from "../assets/icons/edit_white.svg";
import DeleteWhite from "../assets/icons/delete_white.svg";
import RoomShiftWhite from "../assets/icons/room_shift_white.svg";
import ViewWhite from "../assets/icons/view_white.svg";
import PrintWhite from "../assets/icons/print_white.svg";
import Camera from "../assets/icons/camera_icon.svg";
import DashboardWhite from "../assets/icons/dashboard_white.svg";
import ReservationWhite from "../assets/icons/reservation_white.svg";
import GuestHistoryWhite from "../assets/icons/guesthistory_white.svg";
import housekeepWhite from "../assets/icons/housekeep_white.svg";
import Dashboard from "../assets/icons/dash_board_icon.svg";
import Reservation from "../assets/icons/reservation_icon.svg";
import GuestHistory from "../assets/icons/guestinfo_icon.svg";
import HouseKeep from "../assets/icons/house_keeping.svg";
import UnderMaintenanceWhite from "../assets/icons/under_maintenance_grey.svg";
import RoomViewWhite from "../assets/icons/room_view_white.svg";
import HouseKeepNav from "../assets/icons/bottom_nav_housekeep.svg";
import HomeNav from "../assets/icons/bottom_nav_home.svg";
import ActivityNav from "../assets/icons/bottom_nav_activity.svg";
import HouseKeepNavActive from "../assets/icons/bottom_nav_housekeep_active.svg";
import HomeNavActive from "../assets/icons/bottom_nav_home_active.svg";
import ActivityNavActive from "../assets/icons/bottom_nav_activity_active.svg";
import RecordPayment from "../assets/icons/record_payment.svg";
import RecordPaymentWhite from "../assets/icons/record_payment_white.svg";
import PaymentHistory from "../assets/icons/payment_history.svg";
import PaymentHistoryWhite from "../assets/icons/payment_history_white.svg";
import AdvanceReceipt from "../assets/icons/advance_receipt.svg";
import AdvanceReceiptWhite from "../assets/icons/advance_receipt_white.svg";
import QuickCheckIn from "../assets/icons/quick_check_in.svg";
import QuickCheckOut from "../assets/icons/quick_check_out.svg";
import QuickReservation from "../assets/icons/quick_reservation.svg";
import QuickReservationYellow from "../assets/icons/quick_reservation_yellow.svg";
import checkinActive from "../assets/icons/check_in_active.svg";
import checkoutActive from "../assets/icons/check_out_active.svg";
import BlockedIcon from "../assets/icons/blocked_icon.svg";
import BlockedIconWhite from "../assets/icons/blocked_icon_white.svg";
import Pax from "../assets/icons/pax.svg";
import Address from "../assets/icons/address.svg";
import CheckInAction from "../assets/icons/checkin.svg";
import CheckInActionWhite from "../assets/icons/checkinwhite.svg";
import CheckOutAction from "../assets/icons/checkout.svg";
import CheckOutActionWhite from "../assets/icons/checkoutwhite.svg";
import RoomShiftHistory from "../assets/icons/room_shift_history.svg";
import RoomShiftHistoryWhite from "../assets/icons/room_shift_history_white.svg";
import AddPerson from "../assets/icons/add_person_green.svg";
import AddPersonActive from "../assets/icons/add_person_white.svg";
import RemovePerson from "../assets/icons/remove_person_red.svg";
import RemovePersonActive from "../assets/icons/remove_person_white.svg";
import Info from "../assets/icons/info.svg";
import InfoActive from "../assets/icons/info_active.svg";
import HouseKeeperDeleteIcon from "../assets/icons/house_keeper_delete.svg";
import HouseKeepingSummaryExpandIcon from "../assets/icons/expand_icon.svg";
import HouseKeepingFloorCodeDirty from "../assets/icons/housekeeping_floor_dirty.svg";
import HouseKeepingDoorDirty from "../assets/icons/housekeeping_door_dirty.svg";
import HouseKeepingBedDirty from "../assets/icons/housekeeping_bed_dirty.svg";
import HouseKeepingFloorCodeAssigned from "../assets/icons/housekeeping_floor_assigned.svg";
import HouseKeepingDoorAssigned from "../assets/icons/housekeeping_door_assigned.svg";
import HouseKeepingBedAssigned from "../assets/icons/housekeeping_bed_assigned.svg";
import HouseKeepingFloorCodeClean from "../assets/icons/housekeeping_floor_clean.svg";
import HouseKeepingDoorClean from "../assets/icons/housekeeping_door_clean.svg";
import HouseKeepingBedClean from "../assets/icons/housekeeping_bed_clean.svg";
import HouseKeepingSweeperAssigned from "../assets/icons/housekeeping_sweeper_assigned.svg";
import HouseKeepingSweeperClean from "../assets/icons/housekeeping_sweeper_clean.svg";
import HouseKeepingClockClean from "../assets/icons/housekeeping_clock_clean.svg";
import HouseKeepers from "../assets/icons/housekeepers.svg";
import CheckoutRed from "../assets/icons/checkout_red.svg";
import Gallery from "../assets/icons/gallery_icon.svg";
import Capture from "../assets/icons/capture_icon.png";
import CloseCircle from "../assets/icons/close_circle_icon.svg";
import TickCircle from "../assets/icons/tick circle_icon.svg";
import ReservedCheckIn from "../assets/icons/reserved_check_in.svg";
import Report from "../assets/icons/report.svg";
import ReportWhite from "../assets/icons/report_white.svg";
import CalendarReserve from "../assets/icons/calender_reserve.svg";
import CalendarCheckOut from "../assets/icons/calendar_expected.svg";
import CleanedCal from "../assets/icons/calendar_cleaned.svg";
import AssignedCal from "../assets/icons/calendar_assigned.svg";
import DirtyCal from "../assets/icons/calendar_dirty.svg";
import RoomsCal from "../assets/icons/calendar_rooms.svg";
import GuestDetailsGray from "../assets/icons/guestdetails_gray.svg";
import RoomShiftGray from "../assets/icons/roomshift_gray.svg";
import ExtendStayGray from "../assets/icons/extendstay_gray.svg";
import CalendarViewWhite from "../assets/icons/calendar_view_white.svg";
import CalendarView from "../assets/icons/calendar_view_yellow.svg";
import CalendarLeft from "../assets/icons/calendar_left.svg";
import CalendarRight from "../assets/icons/calendar_right.svg";
import NoticationIcon from "../assets/icons/notification.svg";
import CalendarCancel from "../assets/icons/calendar_cancel.svg";
import Calendarblocked from "../assets/icons/calendar_blocked.svg";
import CalendarCheckIn from "../assets/icons/calendar_checkin.svg";
import CalendarCheckOutOption from "../assets/icons/calendar_check_out.svg";
import CalendarAssignRoom from "../assets/icons/calendar_assign_room.svg";
import CalendarBack from "../assets/icons/calendar_back.svg";

let IconConstants = {
  TickCircle,
  NoticationIcon,
  CloseCircle,
  Capture,
  Gallery,
  HouseKeepingClockClean,
  HouseKeepingSweeperClean,
  HouseKeepingSweeperAssigned,
  HouseKeepingBedClean,
  HouseKeepingDoorClean,
  HouseKeepingFloorCodeClean,
  HouseKeepingBedAssigned,
  HouseKeepingDoorAssigned,
  HouseKeepingFloorCodeAssigned,
  HouseKeepingFloorCodeDirty,
  HouseKeepingDoorDirty,
  HouseKeepingBedDirty,
  HouseKeepingSummaryExpandIcon,
  HouseKeeperDeleteIcon,
  CheckOutAction,
  CheckOutActionWhite,
  CheckInActionWhite,
  CheckInAction,
  Address,
  Pax,
  BlockedIconWhite,
  BlockedIcon,
  GuestHivesLogo,
  SuccessToaster,
  HighlightedRoomViewIcon,
  HighlightedFloorViewIcon,
  HighlightedCalendarViewIcon,
  RoomViewIcon,
  FloorViewIcon,
  CalendarViewIcon,
  VacantIcon,
  OccupiedIcon,
  ReservedIcon,
  ArrivalIcon,
  DepartureIcon,
  CleanRoomsIcon,
  CleaningPurple,
  UnCleanRoomsIcon,
  ReloadIcon,
  ExpandIcon,
  ShrinkIcon,
  ExpectedArrivalActive,
  ExpectedArrivalInActive,
  ExpectedDepartureActive,
  ExpectedDepartureInActive,
  PaymentDueActive,
  PaymentDueInActive,
  ThreeDots,
  OrangeThreeDots,
  VerticalThreeDots,
  Reserve,
  VacantDoor,
  ReservedDoor,
  CheckIn,
  OccupiedDoor,
  CheckOut,
  PersonIcon,
  SampleAadhar,
  SampleDrivingLicense,
  EditIcon,
  DeleteIcon,
  ImageEditIcon,
  ToastSuccessIcon,
  DeleteToast,
  AssignTask,
  ExtendStay,
  Reset,
  VacantGreen,
  OccupiedRed,
  FloorView,
  FloorViewWhite,
  RoomView,
  PencilEdit,
  PencilWhite,
  ToastWarnIcon,
  ToastDeleteIcon,
  ToastErrorIcon,
  MaintenanceIcon,
  CleanedBlueIcon,
  AssignedBlueIcon,
  DirtyBlueIcon,
  VacantFilter,
  OccupiedFilter,
  ReservedFilter,
  VacantFilterFilled,
  OccupiedFilterFilled,
  ReservedFilterFilled,
  Arrival,
  Departure,
  SearchIcon,
  Maintenance,
  MaintenanceGrey,
  ReserveWhite,
  ExpandGrey,
  VacantWhite,
  AllocatedWhite,
  MaintenanaceWhite,
  CleaningRed,
  CleaningGreen,
  CleaningGold,
  CleaningWhite,
  UncleanedGold,
  UncleanedWhite,
  UncleanedGreen,
  UncleanedPurple,
  CleanedRed,
  CleanedPurple,
  CleanedGold,
  CleanedWhite,
  ExpecArrivalYellow,
  ExpecArrivalRed,
  ExpecDepartGreen,
  ExpecDepartRed,
  ExpecDepartYellow,
  AllocatedViolet,
  Children,
  Adult,
  ExpandButton,
  ResetButton,
  AdultBlack,
  ChildrenBlack,
  DepartureWhite,
  ArrivalWhite,
  TabExpand,
  ExpandRevert,
  Shrink,
  TabShrink,
  Print,
  DeleteIconWhite,
  EditIconYellow,
  RSDeleteIcon,
  RSEditIcon,
  DoubleBedIcon,
  SingleBedIcon,
  NonAc,
  Ac,
  Edit,
  View,
  Cancel,
  ExtendWhite,
  CancelWhite,
  PrintYellow,
  GIDelete,
  RoomShift,
  EditWhite,
  DeleteWhite,
  RoomShiftWhite,
  ViewWhite,
  PrintWhite,
  Camera,
  DashboardWhite,
  ReservationWhite,
  GuestHistoryWhite,
  housekeepWhite,
  HouseKeep,
  GuestHistory,
  Reservation,
  Dashboard,
  UnderMaintenanceWhite,
  RoomViewWhite,
  ActivityNav,
  HomeNav,
  HouseKeepNav,
  HouseKeepNavActive,
  HomeNavActive,
  ActivityNavActive,
  RecordPaymentWhite,
  RecordPayment,
  PaymentHistoryWhite,
  PaymentHistory,
  AdvanceReceipt,
  AdvanceReceiptWhite,
  QuickCheckIn,
  QuickCheckOut,
  QuickReservation,
  QuickReservationYellow,
  checkoutActive,
  checkinActive,
  RoomShiftHistory,
  RoomShiftHistoryWhite,
  AddPerson,
  AddPersonActive,
  RemovePerson,
  RemovePersonActive,
  Info,
  InfoActive,
  HouseKeepers,
  CheckoutRed,
  ReservedCheckIn,
  Report,
  ReportWhite,
  CalendarReserve,
  CalendarCheckOut,
  CleanedCal,
  AssignedCal,
  DirtyCal,
  RoomsCal,
  GuestDetailsGray,
  RoomShiftGray,
  ExtendStayGray,
  CalendarViewWhite,
  CalendarView,
  CalendarLeft,
  CalendarRight,
  CalendarCancel,
  Calendarblocked,
  CalendarCheckIn,
  CalendarCheckOutOption,
  CalendarAssignRoom,
  CalendarBack
};

export default IconConstants;
