import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Popover,
  TextField,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { RESERVATION_STATUSES } from "../../utils/Constants";
import { Trans, useTranslation } from "react-i18next";
import ApiClient from "../../apis/Api-client";
import { END_POINT } from "../../utils/End-points";
import { Helper } from "../../utils/Helpers";
import { errorToster, successToster } from "../../shared/toster/Toster";
import { useDispatch } from "react-redux";
import {
  selectTriggered,
  setTriggered,
} from "../../core/store/redux/slices/DashboardApi-slice";
import { useSelector } from "react-redux";
const { CONFIRMED, TENTATIVE } = RESERVATION_STATUSES;
const { getLocalTimeFromUtc } = new Helper();

const AssignRoomModal = ({
  data,
  assignRoomAnchorEl,
  handleClosePopover,
  setAssignRoomAnchorEl,
  reset,
}) => {
  const {
    checkin_date,
    checkout_date,
    room_type_id,
    reservation_key,
    reservation_id,
  } = data;

  const isOpen = Boolean(assignRoomAnchorEl);
  const { t } = useTranslation();
  const { api } = ApiClient();
  const theme = useTheme();
  let primaryColor = theme.palette.orange_theme.primary.main;
  let contrastText = theme.palette.orange_theme.primary.contrastText;
  const isTriggered = useSelector(selectTriggered);
  const dispatch = useDispatch();
  const [availableRoomsByType, setAvailableRoomsByType] = useState([]);
  const [roomId, setRoomId] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [reservationStatuses, setReservationStatuses] = useState([]);

  useEffect(() => {
    isOpen && setInitialData();
  }, [isOpen]);

  const setInitialData = async () => {
    try {
      const res = await api.get(
        END_POINT.RESERVATION.GET_ALL_RESERVATION_STATUS
      );
      if (res.status === 200) {
        setReservationStatuses(res?.data?.data || []);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!!room_type_id) {
      setAvailableRoomsData();
    }
  }, [room_type_id]);

  const handleClosePop = () => {
    setAssignRoomAnchorEl(null);
  };

  const setAvailableRoomsData = async () => {
    try {
      const res = await api.post(
        END_POINT.ROOM.GET_AVAILABLE_ROOMS_TO_RESERVE,
        {
          checkin_date: getLocalTimeFromUtc(checkin_date)?.dateTimeString,
          checkout_date: getLocalTimeFromUtc(checkout_date)?.dateTimeString,
          room_type_id,
        }
      );
      if (res?.status === 200) {
        setAvailableRoomsByType(res?.data?.data || []);
      }
    } catch (error) {
      setAvailableRoomsByType([]);
    }
  };

  const handleAssignRoom = async () => {
    setIsSubmitted(true);
    try {
      const roomNo = availableRoomsByType.find(
        (v) => v.room_id === roomId
      )?.room_no;

      let response = await api.post(END_POINT.ROOM.ASSIGN_ROOM_TO_RESERVE, {
        reservation_id,
        reservation_key,
        room_id: roomId,
      });

      if (response?.status === 200) {
        handleClosePopover();
        successToster(
          <Trans
            i18nKey="common_components.assign_room.toast.assigned_success"
            values={{
              room_no: roomNo,
            }}
            components={{ strong: <strong /> }}
          />
        );
        dispatch(setTriggered(!isTriggered));
        reset();
      }
    } catch (error) {
      errorToster("Something went wrong!");
    } finally {
      setTimeout(() => {
        setIsSubmitted(false);
      }, 2000);
    }
  };

  return (
    <Popover
      id={isOpen ? "assign-room-popover" : undefined}
      open={isOpen}
      anchorEl={assignRoomAnchorEl}
      onClose={handleClosePop}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      sx={{
        "& .MuiPaper-root": {
          borderRadius: "16px",
        },
      }}
    >
      <Box
        sx={{
          width: { xs: "52vw", sm: "41.007vw", md: "23.75vw" },
          padding: "10.6% 0",
        }}
      >
        <Box
          component="form"
          sx={{ width: "81%", margin: "0 auto" }}
          onSubmit={(e) => {
            e.preventDefault();
            handleAssignRoom();
          }}
        >
          <FormControl fullWidth>
            <TextField
              autoComplete="off"
              select
              name={"room_id"}
              size="small"
              label={t("common_components.room_shift_popup.room_number")}
              onChange={(e) => setRoomId(e.target.value)}
              InputLabelProps={{
                sx: {
                  fontSize: {
                    xs: "3.077vw",
                    sm: "1.92vw",
                    md: "1.12vw",
                  },
                  color: "#A3A3A3",
                },
              }}
              InputProps={{
                sx: {
                  fontSize: {
                    xs: "3.077vw",
                    sm: "1.92vw",
                    md: "1.12vw",
                  },
                },
              }}
              FormHelperTextProps={{
                sx: {
                  fontSize: {
                    xs: "3vw",
                    sm: "1.44vw",
                    md: "0.83vw",
                  },
                },
              }}
              SelectProps={{
                MenuProps: {
                  PaperProps: {
                    sx: {
                      maxHeight: { xs: "32vw", sm: "20vw", md: "15vw" },
                      overflowY: "auto",
                    },
                  },
                },
              }}
            >
              {availableRoomsByType.length > 0 ? (
                availableRoomsByType.map(
                  ({ room_id, room_no, reservation_status_code }) => {
                    const isDisabled = [TENTATIVE, CONFIRMED].includes(
                      reservationStatuses.find(
                        ({ reservation_status_code: code }) =>
                          code === reservation_status_code
                      )?.reservation_status_code
                    );
                    return (
                      <MenuItem
                        key={room_id}
                        value={room_id}
                        disabled={isDisabled}
                        sx={{
                          backgroundColor: isDisabled ? "#9747FF" : "#D9FFD8",
                          fontSize: {
                            xs: "3.077vw",
                            sm: "1.92vw",
                            md: "1.12vw",
                          },
                          minHeight: { xs: 0, sm: "auto" },
                        }}
                      >
                        {room_no}
                      </MenuItem>
                    );
                  }
                )
              ) : (
                <MenuItem value="" disabled>
                  {t("common_components.room_shift_popup.no_rooms")}
                </MenuItem>
              )}
            </TextField>
          </FormControl>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: "10.9%",
            }}
          >
            <Button
              onClick={handleClosePop}
              variant="outlined"
              sx={{
                width: "47.5%",
                color: primaryColor,
                border: `1px solid ${primaryColor}`,
                fontSize: { xs: "2.821vw", sm: "1.92vw", md: "0.834vw" },
                fontWeight: 700,
                padding: "2.4% 0",
              }}
            >
              {t("common_components.assign_room.cancel")}
            </Button>
            <Button
              variant="contained"
              disabled={!roomId || isSubmitted}
              type="submit"
              sx={{
                width: "47.5%",
                color: contrastText,
                backgroundColor: primaryColor,
                fontSize: { xs: "2.821vw", sm: "1.92vw", md: "0.834vw" },
                fontWeight: 700,
              }}
            >
              {t("common_components.assign_room.confirm")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Popover>
  );
};

export default AssignRoomModal;
