import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { format } from "date-fns";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Helper } from "../../utils/Helpers";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
const helper = new Helper();

const MaintenanceDialog = ({
  handleCloseModal,
  modalFunction,
  reservedDate,
  isLoading,
}) => {
  const themeStyles = useTheme();
  let primaryColor = themeStyles.palette.orange_theme.primary.main;
  let contrastText = themeStyles.palette.orange_theme.primary.contrastText;
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const BlockRoomConfig = Yup.object().shape({
    maintenance_reason: Yup.string().required("Remarks is required"),
    maintenance_until: Yup.date()
      .required("End date is required")
      .typeError("Invalid date")
      .min(new Date(new Date().setHours(0, 0, 0, 0)), "Invalid date")
      .test({
        name: "valid-date",
        message: "Invalid date",
        test: (value) => helper.isValidDate(value),
      }),
  });

  const methods = useForm({
    resolver: yupResolver(BlockRoomConfig),
  });

  const {
    register,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
    watch,
  } = methods;

  const { maintenance_until } = watch();

  const handleClick = async (formData) => {
    const maintenanceUntilDate = new Date(formData.maintenance_until);
    if (isNaN(maintenanceUntilDate.getTime())) {
      setError("maintenance_until", {
        type: "manual",
        message: "Invalid date",
      });
      return;
    }
    await modalFunction(formData);
  };

  return (
    <Box
      sx={{ minWidth: "25vw" }}
      component={"form"}
      onSubmit={handleSubmit(handleClick)}
    >
      <TextField
        fullWidth
        name="maintenance_until"
        {...register("maintenance_until")}
        size="small"
        type="date"
        label={t("pages.home_page.block_room_popup.end_date")}
        sx={{
          mt: "2.5%",
        }}
        InputProps={{
          sx: {
            fontSize: { xs: "3.333vw", sm: "1.92vw", md: "1.12vw" },
          },
        }}
        InputLabelProps={{
          shrink: isMobile && !maintenance_until ? false : true,
          sx: {
            fontWeight: 400,
            fontFamily: "Inter",
            fontSize: { xs: "3.333vw", sm: "1.92vw", md: "1.12vw" },
          },
        }}
        FormHelperTextProps={{
          sx: { fontSize: { xs: "3.08vw", sm: "1.44vw", md: "0.83vw" } },
        }}
        onChange={(e) => {
          setError("maintenance_until", "");
          setValue("maintenance_until", e.target.value);
        }}
        inputProps={{ min: format(new Date(), "yyyy-MM-dd") }}
        error={!!errors.maintenance_until?.message}
        helperText={
          !!errors?.maintenance_until?.message
            ? t(
                `pages.home_page.block_room_popup.${errors.maintenance_until.message
                  ?.toLowerCase()
                  .replace(/\s+/g, "_")}`
              )
            : ""
        }
      />
      <textarea
        name="maintenance_reason"
        {...register("maintenance_reason")}
        rows={8}
        maxLength={200}
        maxRows={4}
        onChange={(e) => {
          setError("maintenance_reason", null);
          setValue("maintenance_reason", e.target.value);
        }}
        onInput={(e) => {
          let { value } = e.target;
          e.target.value = value.replace(/^\s+/, "");
        }}
        placeholder={t("pages.home_page.block_room_popup.remarks")}
        style={{
          width: "100%",
          fontSize: "14px",
          outlineColor: "#BDBDBD",
          borderColor: "#BDBDBD",
          borderRadius: "4px",
          padding: "2.5%",
          marginTop: "5%",
          marginBottom: !!errors.maintenance_reason?.message ? "0.2%" : "5%",
        }}
      />
      {errors?.maintenance_reason?.message && (
        <Typography
          sx={{
            color: "#d32f2f",
            fontSize: { xs: "3.08vw", sm: "1.44vw", md: "0.83vw" },
            margin: "0 0 5% 4%",
          }}
        >
          {t(
            `pages.home_page.block_room_popup.${errors.maintenance_reason?.message
              .toLowerCase()
              .replace(/\s+/g, "_")}`
          )}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "0 auto",
        }}
      >
        <Button
          onClick={handleCloseModal}
          variant="outlined"
          sx={{
            width: "48%",
            color: primaryColor,
            border: `1px solid ${primaryColor}`,
            fontSize: { xs: "3.08vw", sm: "1.92vw", md: "1.11vw" },
            fontWeight: 700,
          }}
        >
          {t("pages.home_page.block_room_popup.cancel")}
        </Button>
        <Button
          variant="contained"
          disabled={isLoading}
          type="submit"
          sx={{
            width: "48%",
            color: contrastText,
            backgroundColor: primaryColor,
            fontSize: { xs: "3.08vw", sm: "1.92vw", md: "1.11vw" },
            fontWeight: 700,
          }}
        >
          {t("pages.home_page.block_room_popup.save")}
        </Button>
      </Box>

      {reservedDate == null ? (
        <Typography
          sx={{
            color: "#D59600",
            fontWeight: 400,
            fontSize: { xs: "3.08vw", sm: "1.44vw", md: "0.83vw" },
            margin: "3% 0 0 0",
          }}
        >
          {t(
            "pages.home_page.block_room_popup.please_be_aware_of_the_dates_to_avoid_scheduling_issues"
          )}
        </Typography>
      ) : (
        <Typography
          sx={{
            color: "#D59600",
            fontWeight: 400,
            fontSize: 12,
            margin: "3% 0 0 0",
          }}
        >
          This room has reservation on{" "}
          {moment(reservedDate).format("DD-MM-YYYY")}. <br />
          Please be aware of the dates to avoid scheduling issues.
        </Typography>
      )}
    </Box>
  );
};

export default MaintenanceDialog;
