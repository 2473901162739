import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import MonthView from "./MonthView.jsx";
import WeekView from "./WeekView.jsx";
import { useEffect, useState } from "react";
import { startOfMonth } from "date-fns";
import { dashboardViewTypes } from "../../pages/dashboard/ReceptionDashboard";
import { useTranslation } from "react-i18next";
import IconConstants from "../../utils/IconConstants";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useSelector } from "react-redux";
import { selectCalendarData } from "../../core/store/redux/slices/Calendar-slice.js";

const Calendar = ({
  roomTypeData,
  setIsExpand,
  isExpanded,
  viewType,
  setViewType,
  view,
  setView,
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const isEnglish = currentLanguage === "en";

  useEffect(() => {
    const storedView = localStorage.getItem("calenderView");
    setView(storedView || "Month");
  }, []);

  const [startFrom, setStartFrom] = useState(() => {
    const savedDate = localStorage.getItem("startFrom");
    return savedDate ? new Date(savedDate) : new Date();
  });
  const isMonthView = view === "Month";
  const [month, setMonth] = useState(() => {
    const savedMonth = localStorage.getItem("month");
    return savedMonth ? dayjs(savedMonth) : dayjs();
  });
  const [dayClicked, setDayClicked] = useState(false);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const bookingData = useSelector(selectCalendarData);

  useEffect(() => {
    if (isExpanded) {
      window.scrollTo(0, 0);
    }
  }, [isExpanded]);

  useEffect(() => {
    if (bookingData) {
      setView("Week");
      setIsExpand(true);
      localStorage.setItem("calenderView", "Week");
      localStorage.setItem("isCalendarExpand", JSON.stringify(true));
    }
  }, [bookingData]);

  const handleMonthChanges = (date, dateString) => {
    const newDate = new Date(date);
    setStartFrom(newDate);
    setMonth(date);
    localStorage.setItem("startFrom", newDate.toISOString());
    localStorage.setItem("month", date);
  };

  const handleViewChange = (newView) => {
    setView(newView);
    localStorage.setItem("calenderView", newView);
    if (newView === "Month") {
      setStartFrom(startOfMonth(startFrom));
      localStorage.setItem("startFrom", startFrom.toISOString());
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          mb: { xs: "2%", sm: "2%", md: "1.5%" },
          justifyContent: { sm: isExpanded ? "space-between" : "flex-start" },
          alignItems: "center",
          gap: { xs: "2.5vw", sm: "1.918vw", md: "1.389vw" },
          width: "100%",
        }}
      >
        {!isMonthView && (
          <Grid
            item
            xs={1}
            sm={0.5}
            md={isExpanded ? 0.3 : 0.5}
            order={{ xs: 1 }}
          >
            <IconButton
              sx={{ p: 0 }}
              size="small"
              onClick={() => handleViewChange("Month")}
            >
              <Box
                sx={{
                  width: { xs: "7.692vw", sm: "3.598vw", md: "2.084vw" },
                  height: { xs: "7.692vw", sm: "3.598vw", md: "2.084vw" },
                }}
              >
                <img
                  src={IconConstants.CalendarBack}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            </IconButton>
          </Grid>
        )}

        <Grid
          item
          xs={3.68}
          sm={1.9}
          md={isExpanded ? 1.3 : 1.74}
          order={{ xs: 3 }}
        >
          <Box
            sx={{
              display: { xs: "flex" },
              border: "0.5px solid #FFDC8A",
              borderRadius: "4px",
              height: { xs: "7.692vw", sm: "3.598vw", md: "2.084vw" },
            }}
          >
            {dashboardViewTypes.map((option, index) => (
              <Tooltip
                title={t(
                  `pages.home_page.room_view_section.${option.value
                    .toLowerCase()
                    .replace(/\s+/g, "_")}`
                )}
              >
                <Box
                  key={option.value}
                  onClick={() => {
                    setViewType(option.value);
                    localStorage.setItem("viewType", option.value);
                    if (option.value !== "Calendar View") {
                      setIsExpand(false);
                      localStorage.setItem("isCalendarExpand", JSON.stringify(false));
                    }
                  }}
                  sx={{
                    width: "33.34%",
                    backgroundColor:
                      viewType === option.value ? "#DEA829" : "#F9F1DD",
                    borderRadius: index === 0 ? "3px 0 0 3px" : "0 3px 3px 0",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all 0.3s ease",
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{
                      height: { xs: "5.128vw", sm: "2.398vw", md: "1.389vw" },
                      width: { xs: "5.128vw", sm: "2.398vw", md: "1.389vw" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        viewType === option.value
                          ? option.activeIcon
                          : option.icon
                      }
                      alt={option.value}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                </Box>
              </Tooltip>
            ))}
          </Box>
        </Grid>

        {isMonthView && (
          <Grid
            item
            xs={4}
            sm={1.9}
            md={isExpanded ? 1.3 : 2.1}
            order={{ xs: isExpanded ? 2 : 4, sm: 2 }}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                views={["year", "month"]}
                value={month}
                format="MMM YYYY"
                openTo="month"
                onChange={handleMonthChanges}
                slots={{
                  openPickerIcon: () => (
                    <Box
                      sx={{
                        height: { xs: "5.128vw", sm: "2.398vw", md: "1.389vw" },
                        width: { xs: "5.128vw", sm: "2.398vw", md: "1.389vw" },
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={IconConstants.CalendarView}
                        alt="calendar"
                        style={{ width: "100%", height: "100%" }}
                      />
                    </Box>
                  ),
                }}
                slotProps={{
                  desktopPaper: {
                    sx: {
                      maxHeight: { md: "21vw" },
                      overflowY: "auto",
                      borderRadius: "15px",
                      "& .MuiPickersYear-yearButton, & .MuiPickersMonth-monthButton":
                        {
                          fontSize: { md: "0.972vw" },
                          "&.Mui-selected": {
                            backgroundColor: "#DEA829 !important",
                            color: "#FFFFFF",
                            fontWeight: 500,
                          },
                        },
                    },
                  },
                  mobilePaper: {
                    sx: {
                      borderRadius: "15px",
                    },
                  },
                  field: {
                    readOnly: true,
                  },
                  textField: {
                    size: "small",
                    InputProps: isMobile && {
                      endAdornment: (
                        <InputAdornment position="end">
                          <Box
                            sx={{
                              height: {
                                xs: "5.128vw",
                                sm: "2.398vw",
                                md: "1.389vw",
                              },
                              width: {
                                xs: "5.128vw",
                                sm: "2.398vw",
                                md: "1.389vw",
                              },
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={IconConstants.CalendarView}
                              alt="calendar"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </Box>
                        </InputAdornment>
                      ),
                    },
                    sx: {
                      "& .MuiOutlinedInput-root": {
                        height: {
                          xs: "7.692vw",
                          sm: "3.597vw",
                          md: "2.083vw",
                        },
                        fontSize: {
                          xs: "3.077vw",
                          sm: "1.44vw",
                          md: "0.834vw",
                        },
                        borderRadius: "4px",
                        backgroundColor: "#FFFBF1",
                        color: "#25213B",
                        borderColor: "#FFDC8A !important",
                        "& fieldset": {
                          borderColor: "#FFDC8A",
                        },
                        "&:hover fieldset": {
                          borderColor: "#FFDC8A  !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#DEA829 !important",
                          borderWidth: "1px",
                          borderColor: "#FFDC8A  !important",
                        },
                        paddingRight: { xs: "5%", sm: "8%", md: "10%" },
                      },
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" size="small" />
                )}
              />
            </LocalizationProvider>
          </Grid>
        )}
        <Grid
          item
          xs={1.1}
          sm={isExpanded ? 1.08 : 1.45}
          sx={{
            ml: "auto",
          }}
          order={4}
        >
          <Box>
            <Tooltip
              title={t(
                `pages.home_page.room_view_section.${
                  isExpanded ? "collapse" : "expand"
                }`
              )}
            >
              <Box
                sx={{
                  height: { xs: "7.692vw", sm: "3.597vw", md: "2.08vw" },
                  width: { xs: "7.692vw", sm: "3.597vw", md: "2.08vw" },
                  cursor: "pointer",
                  ml: "auto",
                }}
                onClick={() => {
                  setIsExpand((prev) => {
                    const newValue = !prev;
                    localStorage.setItem(
                      "isCalendarExpand",
                      JSON.stringify(newValue)
                    );
                    return newValue;
                  });
                }}
              >
                <img
                  src={
                    isExpanded ? IconConstants.Shrink : IconConstants.ExpandIcon
                  }
                  style={{
                    height: "100%",
                    width: "100%",
                    transition: "all 0.3s ease",
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        </Grid>
      </Grid>
      {view === "Month" ? (
        <Box sx={{ overflowY: "auto" }}>
          <MonthView
            selectedDate={startFrom}
            isExpanded={isExpanded}
            setView={setView}
            setStartFrom={setStartFrom}
            setMonth={setMonth}
            isEnglish={isEnglish}
            setDayClicked={setDayClicked}
          />
        </Box>
      ) : (
        <WeekView
          roomTypeData={roomTypeData}
          selectedDate={startFrom}
          isExpanded={isExpanded}
          isEnglish={isEnglish}
          dayClicked={dayClicked}
          setDayClicked={setDayClicked}
        />
      )}
    </>
  );
};

export default Calendar;
