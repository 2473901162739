import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Modal,
  Fade,
  Popover,
  Button,
  useMediaQuery,
} from "@mui/material";
import {
  format,
  addDays,
  eachDayOfInterval,
  subDays,
  isSameDay,
  parse,
} from "date-fns";
import { ArrowDropUp, ArrowDropDown, Info } from "@mui/icons-material";
import IconConstants from "../../utils/IconConstants";
import { END_POINT } from "../../utils/End-points";
import ApiClient from "../../apis/Api-client";
import { Helper } from "../../utils/Helpers";
import {
  OCCUPANCY_STATUSES,
  RESERVATION_STATUSES,
  USER_ROLES,
} from "../../utils/Constants";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { setDashBoardData } from "../../core/store/redux/slices/Dashboard-slice";
import RoomShift from "../../components/roomshift/Room-shift";
import ExtendStay from "../../components/extendstay/Extend-stay";
import { useTranslation } from "react-i18next";
import moment from "moment";
import CancelReservationModal from "../reservation/CancelReservationModal";
import AssignRoomModal from "../reservation/AssignRoomModal";
import {
  selectCalendarData,
  setCalendarData,
} from "../../core/store/redux/slices/Calendar-slice";
import { debounce } from "lodash";
import { CustomTooltip } from "../reports/ColumnUtils";
import { trackPromise } from "react-promise-tracker";
const { getLocalTimeFromUtc } = new Helper();
const { CHECK_IN } = OCCUPANCY_STATUSES;
const { CONFIRMED, TENTATIVE } = RESERVATION_STATUSES;
const { ADMIN, RECEPTIONIST } = USER_ROLES;

const houseKeepingIcons = {
  clean: IconConstants.CleanedCal,
  assigned: IconConstants.AssignedCal,
  dirty: IconConstants.DirtyCal,
};

const WeekView = ({
  selectedDate,
  roomTypeData,
  isExpanded,
  dayClicked,
  setDayClicked,
  isEnglish,
}) => {
  const { api } = ApiClient();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = new URLSearchParams();
  const [anchorEl, setAnchorEl] = useState(null);
  const [assignRoomAnchorEl, setAssignRoomAnchorEl] = useState(null);
  const openpop = Boolean(anchorEl);
  const id = openpop ? "calendar-popover" : undefined;
  const isTab = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isMob = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const bookingData = useSelector(selectCalendarData);
  const [startDate, setStartDate] = useState(selectedDate);
  const [events, setEvents] = useState([]);
  const [expandedRoomTypes, setExpandedRoomTypes] = useState({});
  const [modalContent, setModalContent] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [activeRowData, setActiveRowData] = useState({});
  const [daysOfMonths, setDaysOfMonths] = useState([]);
  const dayRefs = useRef([]);
  const barRefs = useRef({});
  const tableContainerRef = useRef(null);
  const [bookingId, setBookingId] = useState("");
  const isUpdating = useRef(false);
  const [activeTooltip, setActiveTooltip] = useState(null);

  useEffect(() => {
    const container = tableContainerRef.current;
    let startX, startY, isScrollingHorizontally, isScrollingVertically;

    const handleTouchStart = (e) => {
      startX = e.touches[0].clientX;
      startY = e.touches[0].clientY;
      isScrollingHorizontally = false;
      isScrollingVertically = false;
    };

    const handleTouchMove = (e) => {
      const moveX = e.touches[0].clientX - startX;
      const moveY = e.touches[0].clientY - startY;

      if (!isScrollingHorizontally && !isScrollingVertically) {
        if (Math.abs(moveX) > Math.abs(moveY)) {
          isScrollingHorizontally = true;
        } else {
          isScrollingVertically = true;
        }
      }

      if (isScrollingHorizontally) {
        e.preventDefault(); // Prevent vertical scrolling when moving horizontally
        container.scrollLeft -= moveX * 0.1;
      }

      if (isScrollingVertically) {
        e.preventDefault(); // Prevent horizontal scrolling when moving vertically
        container.scrollTop -= moveY * 0.1;
      }
    };

    if (container) {
      container.addEventListener("touchstart", handleTouchStart, {
        passive: false,
      });
      container.addEventListener("touchmove", handleTouchMove, {
        passive: false,
      });
    }

    return () => {
      if (container) {
        container.removeEventListener("touchstart", handleTouchStart);
        container.removeEventListener("touchmove", handleTouchMove);
      }
    };
  }, []);

  const addNoRoomReservation = (roomTypeData) => {
    return roomTypeData.map((roomType) => {
      const noRoom = {
        room_id: roomType.room_type_key + roomType.room_type_id,
        room_no: "",
      };
      const updatedRooms = [...roomType.rooms, noRoom];
      return {
        ...roomType,
        rooms: updatedRooms,
      };
    });
  };

  const updatedRoomTypeData = addNoRoomReservation(roomTypeData);

  const handleClosePopover = () => {
    setAssignRoomAnchorEl(null);
    setOpenModal(false);
    setAnchorEl(null);
    setTimeout(() => {
      setActiveRowData({});
    });
  };

  const handleTooltipToggle = (roomId) => {
    setActiveTooltip((prevId) => (prevId === roomId ? null : roomId));
  };

  const handleTooltipClose = () => {
    setActiveTooltip(null);
  };

  const startOfPrevMonth = useMemo(() => {
    return daysOfMonths.length > 0 ? daysOfMonths[0] : null;
  }, [daysOfMonths]);

  const endOfNextMonth = useMemo(() => {
    return daysOfMonths.length > 0
      ? daysOfMonths[daysOfMonths.length - 1]
      : null;
  }, [daysOfMonths]);

  useEffect(() => {
    if (startOfPrevMonth) {
      const debounceFetch = setTimeout(() => {
        getAllBookings();
      }, 100);

      return () => clearTimeout(debounceFetch);
    }
  }, [startOfPrevMonth, endOfNextMonth]);

  const getAllBookings = async () => {
    const from_date = moment(new Date(startOfPrevMonth))
      .subtract(10, "days")
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");

    const to_date = moment(new Date(endOfNextMonth))
      .add(10, "days")
      .endOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    try {
      const response = await api.post(END_POINT.BOOKING.GET_ALL_BOOKING, {
        from_date,
        to_date,
      });
      if (response.status === 200) {
        const { data } = response?.data;
        if (data?.length) {
          const updatedData = data.map((v, _, allBookings) => {
            const localCheckInDate = getLocalTimeFromUtc(
              v.checkin_date
            )?.dateString;
            const localCheckoutDate = getLocalTimeFromUtc(
              v.checkout_date
            )?.dateString;

            const findOverlappingBookings = (booking, bookings) => {
              const bookingCheckInDate = getLocalTimeFromUtc(
                booking.checkin_date
              )?.dateString;
              const bookingCheckoutDate = getLocalTimeFromUtc(
                booking.checkout_date
              )?.dateString;

              return bookings.filter((otherBooking) => {
                const otherCheckInDate = getLocalTimeFromUtc(
                  otherBooking.checkin_date
                )?.dateString;
                const otherCheckoutDate = getLocalTimeFromUtc(
                  otherBooking.checkout_date
                )?.dateString;

                return (
                  (booking.room_id
                    ? booking.room_id === otherBooking.room_id
                    : booking.room_type_key === otherBooking.room_type_key) &&
                  bookingCheckInDate <= otherCheckoutDate &&
                  bookingCheckoutDate >= otherCheckInDate
                );
              });
            };

            // Get the latest checkout date for overlapping bookings
            const getLatestCheckoutDate = (booking, bookings) => {
              let maxCheckoutDate = getLocalTimeFromUtc(
                booking.checkout_date
              )?.dateString;

              const queue = [booking];
              const visited = new Set();
              visited.add(booking.reservation_id);

              while (queue.length) {
                const currentBooking = queue.shift();
                const overlappingBookings = findOverlappingBookings(
                  currentBooking,
                  bookings
                );

                for (const overlap of overlappingBookings) {
                  const overlapCheckoutDate = getLocalTimeFromUtc(
                    overlap.checkout_date
                  )?.dateString;

                  if (!visited.has(overlap.reservation_id)) {
                    visited.add(overlap.reservation_id);
                    queue.push(overlap);
                  }

                  if (overlapCheckoutDate > maxCheckoutDate) {
                    maxCheckoutDate = overlapCheckoutDate;
                  }
                }
              }

              return maxCheckoutDate;
            };

            const latestCheckoutDate = getLatestCheckoutDate(v, allBookings);

            return {
              ...v,
              roomId: v.room_id || null,
              checkin: localCheckInDate,
              checkout: localCheckoutDate,
              status: v.booking_status,
              customerName: v.name,
              fake_checkout: latestCheckoutDate,
            };
          });

          setEvents(updatedData);
        }
      }
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  const toggleExpand = useCallback(
    debounce((roomTypeKey) => {
      trackPromise(
        new Promise((resolve) => {
          setTimeout(() => {
            setExpandedRoomTypes((prev) => ({
              ...prev,
              [roomTypeKey]: !prev[roomTypeKey],
            }));
            resolve("Expanded");
          }, 10);
        })
      );
    }, 5),
    []
  );

  useEffect(() => {
    if (roomTypeData?.length > 0 && !bookingData) {
      setExpandedRoomTypes({
        [roomTypeData[0].room_type_name]: true,
      });
    }
  }, [roomTypeData]);

  useEffect(() => {
    if (bookingData && events.length > 0) {
      const { checkin_date, room_type_name, booking_id } = bookingData ?? {};
      setStartDate(checkin_date);
      setBookingId(booking_id);
      setExpandedRoomTypes({
        [room_type_name]: true,
      });
      const booking = events.find((event) => event.booking_id === booking_id);
      if (booking) {
        const checkInDate = new Date(booking.checkin);
        const columnIndex = daysOfMonths.findIndex((day) =>
          isSameDay(new Date(day), checkInDate)
        );
        if (columnIndex !== -1 && dayRefs.current[columnIndex]) {
          dayRefs.current[columnIndex].scrollIntoView({
            behavior: "smooth",
            inline: "center",
          });

          const barRef = barRefs.current[booking_id];
          if (barRef) {
            barRef.scrollIntoView({
              behavior: "smooth",
              block: "center",
              inline: "center",
            });
            dispatch(setCalendarData());
            window.scrollTo(0, 0);
          }
        }
      }
    }
  }, [events, bookingData]);

  const normalizeDate = (date) => {
    return moment(date).startOf("day").toDate();
  };

  const getCellStatus = (roomId, date, weekStart, weekEnd) => {
    const normalizedDate = normalizeDate(date);

    const bookingsForDate = events.filter((b) => {
      const checkinDate = normalizeDate(b.checkin);
      const checkoutDate = normalizeDate(b.fake_checkout || b.checkout);
      return (
        (!!b.roomId
          ? b.roomId === roomId
          : roomId === b.room_type_key + b.room_type_id) &&
        normalizedDate >= checkinDate &&
        normalizedDate <= checkoutDate
      );
    });

    bookingsForDate.sort((a, b) => new Date(a.checkin) - new Date(b.checkin));

    return bookingsForDate.map((booking) => {
      const checkinDate = normalizeDate(booking.checkin);
      const isFirstVisibleCell =
        checkinDate >= weekStart && checkinDate <= weekEnd
          ? normalizedDate.getTime() === checkinDate.getTime()
          : normalizedDate.getTime() === weekStart.getTime();

      return {
        ...booking,
        isFirstVisibleCell,
      };
    });
  };

  const getEventBarStyle = ({
    status,
    reservation_key,
    booking_id,
    isRealCheckout,
  }) => {
    const isActive =
      activeRowData?.reservation_key === reservation_key ||
      booking_id === bookingId;
    return {
      backgroundColor: (() => {
        if (isRealCheckout) {
          if (status === CHECK_IN) {
            return isActive ? "#ED705B" : "#FFE7E4";
          }
          return isActive ? "#9747FF" : "#F3EAFF";
        }
        return "#FFFFFF";
      })(),
      color: isActive ? "#FFFFFF" : status === CHECK_IN ? "#ED705B" : "#9747FF",
      width: isRealCheckout ? "calc(100% + 2px)" : "100%",
      marginLeft: isRealCheckout ? "-1px" : 0,
      marginRight: isRealCheckout ? "-1px" : 0,
      zIndex: 1,
      height: { xs: "5.972vw", sm: "3.597vw", md: "2.084vw" },
      display: "flex",
      alignItems: "center",
      mt: { xs: "1vw", sm: "0.719vw", md: "0.417vw" },
      pl: "4%",
      borderTop: (() => {
        if (isRealCheckout) {
          if (status === CHECK_IN) {
            return `0.139vw solid ${isActive ? "#FFE7E4" : "#ED705B"}`;
          }
          return `0.139vw solid ${isActive ? "#F3EAFF" : "#9747FF"}`;
        }
        return "none";
      })(),
      cursor: isRealCheckout ? "pointer" : "default",
    };
  };

  const getBlockedBarStyle = (isOriginalBlockedRange) => {
    return {
      backgroundColor: isOriginalBlockedRange ? "#EFEFEF" : "transparent",
      color: "#000000",
      width: "100%",
      width: "calc(100% + 2px)",
      marginLeft: "-1px",
      marginRight: "-1px",
      height: { xs: "5.972vw", sm: "3.597vw", md: "2.084vw" },
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      mt: { xs: "1vw", sm: "0.719vw", md: "0.417vw" },
      pl: "4%",
      borderTop: isOriginalBlockedRange ? "0.139vw solid #C7C7C7" : "none",
      cursor: "default",
    };
  };

  const optionsData = [
    {
      label: "Check In",
      icon: IconConstants.CalendarCheckIn,
      action: () => {
        setTimeout(() => {
          params.append("reservation_key", activeRowData?.reservation_key);
          navigate(`/main/arrival/reservedcheckin?${params.toString()}`);
        });
      },
      authorizedRoles: [ADMIN, RECEPTIONIST],
    },
    {
      label: "Assign Room",
      icon: IconConstants.CalendarAssignRoom,
      action: () => {
        setAssignRoomAnchorEl(activeRowData?.currentTarget);
      },
      authorizedRoles: [ADMIN, RECEPTIONIST],
    },
    {
      label: "Check Out",
      icon: IconConstants.CalendarCheckOutOption,
      action: () => {
        setTimeout(() => {
          params.append(
            "occupancy_keys",
            JSON.stringify([
              activeRowData.parent_occupancy_key ?? activeRowData.occupancy_key,
            ])
          );
          navigate(`/main/departure?${params.toString()}`);
        });
      },
      authorizedRoles: [ADMIN, RECEPTIONIST],
    },
    {
      label: "Guest Details",
      icon: IconConstants.GuestDetailsGray,
      action: () => {
        const { reservation_key, occupancy_key } = activeRowData;
        dispatch(setDashBoardData({ occupancy_key, reservation_key }));
        setTimeout(() => {
          navigate(
            `/main/${
              activeRowData?.booking_status === CHECK_IN
                ? "guestinfo"
                : "reservations"
            }`
          );
        });
      },
      authorizedRoles: [ADMIN, RECEPTIONIST],
    },
    {
      label: "Room Shift",
      icon: IconConstants.RoomShiftGray,
      action: () => {
        setModalContent("roomShift");
        setOpenModal(true);
      },
      authorizedRoles: [ADMIN, RECEPTIONIST],
    },
    {
      label: "Extend Stay",
      icon: IconConstants.ExtendStayGray,
      action: () => {
        setModalContent("extendStay");
        setOpenModal(true);
      },
      authorizedRoles: [ADMIN, RECEPTIONIST],
    },
    {
      label: "Cancel Reservation",
      icon: IconConstants.CalendarCancel,
      activeIcon: IconConstants.CancelWhite,
      action: () => {
        setModalContent("cancelReservation");
        setOpenModal(true);
      },
      authorizedRoles: [ADMIN, RECEPTIONIST],
    },
  ];

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleStatusBarClick = (event, data) => {
    setBookingId("");
    setAnchorEl(event.currentTarget);
    setActiveRowData({ ...data, currentTarget: event.currentTarget });
  };

  const handleCellClick = (data) => {
    const { room_key, selectedDate } = data;
    const isToday = selectedDate === new Date().setHours(0, 0, 0, 0);
    params.append("checkin_date", format(new Date(selectedDate), "yyyy-MM-dd"));
    if (isToday) {
      params.append("room_key", room_key);
      navigate(`/main/arrival?${params.toString()}`);
    } else {
      params.append("room_key", room_key);
      navigate(`/main/reservation?${params.toString()}`);
    }
  };

  const parseDate = (dateString) => {
    return parse(dateString, "EEEE dd MMMM yy", new Date());
  };

  const formatDate = (date) => {
    return format(date, "EEEE dd MMMM yy");
  };

  // Initial load: 15 days before and after startDate (total 31 days)
  useEffect(() => {
    const initialStart = subDays(startDate, 15);
    const initialEnd = addDays(startDate, 15);
    const initialDays = eachDayOfInterval({
      start: initialStart,
      end: initialEnd,
    }).map((date) => formatDate(date));
    setDaysOfMonths(initialDays);
  }, [startDate]);

  // Add next 10 days
  const addNextDays = useCallback(() => {
    if (isUpdating.current) return;
    isUpdating.current = true;

    const lastDay = parseDate(daysOfMonths[daysOfMonths.length - 1]);
    const newEnd = addDays(lastDay, 10);
    const newDays = eachDayOfInterval({
      start: addDays(lastDay, 1),
      end: newEnd,
    }).map((date) => formatDate(date));

    setDaysOfMonths((prevDays) => {
      const existingDays = new Set(prevDays);
      const uniqueNewDays = newDays.filter((day) => !existingDays.has(day));
      isUpdating.current = false;
      return [...prevDays, ...uniqueNewDays];
    });
  }, [daysOfMonths]);

  //Add previous 10 days
  const addPreviousDays = useCallback(() => {
    if (isUpdating.current) return;
    isUpdating.current = true;

    const firstDay = parseDate(daysOfMonths[0]);
    const newStart = subDays(firstDay, 10);
    const newDays = eachDayOfInterval({
      start: newStart,
      end: subDays(firstDay, 1),
    }).map((date) => formatDate(date));

    setDaysOfMonths((prevDays) => {
      const existingDays = new Set(prevDays);
      const uniqueNewDays = newDays.filter((day) => !existingDays.has(day));
      isUpdating.current = false;
      return [...uniqueNewDays, ...prevDays];
    });
  }, [daysOfMonths]);

  //Remove oldest 10 days
  const removeOldestDays = useCallback(() => {
    setDaysOfMonths((prevDays) => prevDays.slice(10));
  }, []);

  //Remove newest 10 days
  const removeNewestDays = useCallback(() => {
    setDaysOfMonths((prevDays) => prevDays.slice(0, -10));
  }, []);

  useEffect(() => {
    const container = tableContainerRef.current;
    if (!container) return;

    let lastScrollLeft = container.scrollLeft;
    let lastScrollTop = container.scrollTop;

    const handleScroll = debounce(() => {
      const { scrollLeft, scrollTop, scrollWidth, clientWidth } = container;

      // Check if the scroll is horizontal (scrollLeft has changed)
      const isHorizontalScroll = scrollLeft !== lastScrollLeft;

      // Check if the scroll is vertical (scrollTop has changed)
      const isVerticalScroll = scrollTop !== lastScrollTop;

      // Update last scroll positions
      lastScrollLeft = scrollLeft;
      lastScrollTop = scrollTop;

      if (isVerticalScroll) return;

      if (isHorizontalScroll) {
        const threshold = 12;
        if (Math.ceil(scrollLeft + clientWidth) >= scrollWidth - threshold) {
          addNextDays();
          removeOldestDays();
          setTimeout(() => {
            container.scrollLeft =
              container.scrollWidth / (isMob ? 1.5 : isTab ? 1.6 : 1.8) -
              container.clientWidth / (isMob ? 1.5 : isTab ? 1.6 : 1.8);
          }, 0);
        }

        if (scrollLeft <= threshold) {
          addPreviousDays();
          removeNewestDays();
          setTimeout(() => {
            container.scrollLeft =
              container.scrollWidth / (isMob ? 3.1 : isTab ? 2.7 : 2.2) -
              container.clientWidth / (isMob ? 3.1 : isTab ? 2.7 : 2.2);
          }, 0);
        }
      }
    }, 30);

    container.addEventListener("scroll", handleScroll);
    return () => {
      handleScroll.cancel();
      container.removeEventListener("scroll", handleScroll);
    };
  }, [addNextDays, addPreviousDays, removeOldestDays, removeNewestDays]);

  // Scroll to the startDate column on initial render
  useEffect(() => {
    if (daysOfMonths.length > 0 && tableContainerRef.current && dayClicked) {
      const startDateIndex = daysOfMonths.findIndex((day) =>
        isSameDay(new Date(day), startDate)
      );
      if (startDateIndex !== -1 && dayRefs.current[startDateIndex]) {
        dayRefs.current[startDateIndex].scrollIntoView({
          behavior: "auto",
          inline: "center",
          block: "nearest" 
        });
        // window.scrollTo(0, 0);
        setDayClicked(false);
        console.log("inside Month selected");
      }
    }
  }, [daysOfMonths, startDate]);

  return (
    <>
      <TableContainer
        sx={{
          maxHeight: isExpanded
            ? { xs: "68vh", sm: "98vw", md: "76vh" }
            : { xs: "34.5vh", sm: "51vw", md: "55vh" },
          border: "1px solid #FFDC8A",
          borderRadius: "12px",
          WebkitOverflowScrolling: "touch",
          touchAction: "none"
        }}
        id="scrollable-container"
        ref={tableContainerRef}
      >
        <Table
          stickyHeader
          sx={{
            minWidth: {
              xs: "900px",
              sm: "1200px",
              md: "1500px",
              "& .MuiTableCell-root": {
                padding: "0 !important",
              },
            },
          }}
        >
          <TableHead>
            <TableRow sx={{ height: { md: "4.583vw" } }}>
              <TableCell
                sx={{
                  position: "sticky",
                  left: 0,
                  zIndex: 3,
                  background: "#FFFFFF",
                  border: "0.5px solid #FCF0D4",
                  p: 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: { xs: "14.149vw", sm: "14.03vw", md: "8.194vw" },
                  }}
                >
                  <Box
                    sx={{
                      width: { xs: "5.129vw", sm: "3.837vw", md: "2.223vw" },
                      height: { xs: "5.129vw", sm: "3.837vw", md: "2.223vw" },
                    }}
                  >
                    <img
                      src={IconConstants.RoomsCal}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: { xs: "2.565vw", sm: "1.919vw", md: "1.112vw" },
                      color: "#D8A630",
                      fontWeight: 600,
                    }}
                  >
                    {t("pages.home_page.room_view_section.rooms")}
                  </Typography>
                </Box>
              </TableCell>

              {daysOfMonths.map((weekDay, index) => {
                const [day, date, month, year] = weekDay.split(" ");
                return (
                  <TableCell
                    key={weekDay}
                    align="center"
                    ref={(el) => (dayRefs.current[index] = el)}
                    sx={{
                      border: "0.5px solid #FCF0D4",
                    }}
                  >
                    <Box
                      sx={{
                        width: {
                          xs: "21.026vw",
                          sm: "9.85vw",
                          md: "5.694vw",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "3.077vw",
                            sm: "2.399vw",
                            md: "1.389vw",
                          },
                          fontWeight: 700,
                          color: "#D8A630",
                        }}
                      >
                        {date}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "2.565vw",
                            sm: "1.439vw",
                            md: "0.834vw",
                          },
                          color: "#252525",
                          textWrap: "nowrap",
                        }}
                      >
                        {isEnglish
                          ? `${t(`days.${day.toLowerCase()}`).slice(
                              0,
                              3
                            )} | ${t(`months.${month.toLowerCase()}`).slice(
                              0,
                              3
                            )}`
                          : `${t(`days.${day.toLowerCase()}`)} | ${t(
                              `months.${month.toLowerCase()}`
                            )}`}{" "}
                        {/* {year} */}
                      </Typography>
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {updatedRoomTypeData?.map((group) => (
              <Fragment key={group.room_type_name}>
                <TableRow>
                  <TableCell
                    sx={{
                      position: "sticky",
                      left: 0,
                      zIndex: 1,
                      background: "#FFFFFF",
                      border: "0.5px solid #FCF0D4",
                      cursor: "pointer",
                    }}
                    onClick={() => toggleExpand(group.room_type_name)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: {
                          xs: "4.5% 5%",
                          sm: "11.2% 3%",
                          md: "11.5% 3.5%",
                        },
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#D8A630",
                          fontSize: {
                            xs: "2.565vw",
                            sm: "1.679vw",
                            md: "0.973vw",
                          },
                          fontWeight: 600,
                        }}
                      >
                        {group.room_type_name}
                      </Typography>
                      {expandedRoomTypes[group.room_type_name] ? (
                        <ArrowDropUp sx={{ color: "#AAAAAA" }} />
                      ) : (
                        <ArrowDropDown sx={{ color: "#AAAAAA" }} />
                      )}
                    </Box>
                  </TableCell>
                </TableRow>

                {expandedRoomTypes[group.room_type_name] &&
                  group.rooms.map((room) => (
                    <TableRow
                      key={room.room_id}
                      sx={{ minHeight: { md: "4.167vw" } }}
                    >
                      <TableCell
                        sx={{
                          position: "sticky",
                          left: 0,
                          zIndex: 2,
                          background: "white",
                          border: "0.5px solid #FCF0D4",
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: "1vw",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              sx={{
                                color: "#515151",
                                fontSize: {
                                  xs: "2.565vw",
                                  sm: "1.679vw",
                                  md: room.room_no ? "0.973vw" : "0.834vw",
                                },
                                fontWeight: 500,
                              }}
                              align="center"
                            >
                              {room.room_no ||
                                t(
                                  "pages.home_page.room_view_section.unassigned"
                                )}
                            </Typography>

                            {!!room.room_no ? (
                              <Box>
                                <Box
                                  sx={{
                                    height: {
                                      xs: "5.642vw",
                                      sm: "3.598vw",
                                      md: "2.084vw",
                                    },
                                    width: {
                                      xs: "5.642vw",
                                      sm: "3.598vw",
                                      md: "2.084vw",
                                    },
                                  }}
                                >
                                  <img
                                    alt=""
                                    src={
                                      room.is_under_maintenance
                                        ? IconConstants.Calendarblocked
                                        : houseKeepingIcons[
                                            room.cleaning_status_code
                                          ]
                                    }
                                    loading="lazy"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  />
                                </Box>
                              </Box>
                            ) : (
                              ""
                            )}
                          </Box>
                        </Box>
                      </TableCell>

                      {daysOfMonths.map((day, index) => {
                        const date = addDays(new Date(startOfPrevMonth), index);

                        const bookingInfo = getCellStatus(
                          room.room_id,
                          date,
                          new Date(startOfPrevMonth),
                          new Date(endOfNextMonth)
                        );
                        const selectedDate = date.setHours(0, 0, 0, 0);
                        const isPastDate =
                          selectedDate < new Date().setHours(0, 0, 0, 0);

                        const getMaxCheckoutDate = (bookingInfo) => {
                          return bookingInfo.reduce((maxDate, booking) => {
                            const checkoutDate = new Date(
                              booking.fake_checkout || booking.checkout
                            );
                            return checkoutDate > maxDate
                              ? checkoutDate
                              : maxDate;
                          }, new Date(0));
                        };

                        const getMinCheckInDate = (bookingInfo) => {
                          return bookingInfo.reduce((minDate, booking) => {
                            const checkInDate = new Date(booking.checkin);
                            return checkInDate < minDate
                              ? checkInDate
                              : minDate;
                          }, new Date(0));
                        };

                        const minCheckinDate = getMinCheckInDate(bookingInfo);
                        const maxCheckoutDate = getMaxCheckoutDate(bookingInfo);

                        // Compare with maintenance_until date
                        const maintenanceUntilDate = room.is_under_maintenance
                          ? getLocalTimeFromUtc(room.maintenance_until)
                              ?.dateString
                          : null;

                        const maintenanceStartDate = room.is_under_maintenance
                          ? getLocalTimeFromUtc(room.maintenance_start)
                              ?.dateString
                          : null;

                        const fakeBlockedDate = maintenanceUntilDate
                          ? new Date(
                              Math.max(
                                new Date(maintenanceUntilDate),
                                maxCheckoutDate
                              )
                            )
                          : maxCheckoutDate;

                        const fakeBlockedStartDate = maintenanceStartDate
                          ? new Date(
                              Math.min(
                                new Date(maintenanceStartDate),
                                minCheckinDate
                              )
                            )
                          : minCheckinDate;

                        const isOriginalBlockedRange =
                          maintenanceStartDate &&
                          maintenanceUntilDate &&
                          format(selectedDate, "yyyy-MM-dd") >=
                            format(maintenanceStartDate, "yyyy-MM-dd") &&
                          format(selectedDate, "yyyy-MM-dd") <=
                            format(maintenanceUntilDate, "yyyy-MM-dd");

                        const isOriginalBlockedEndDate =
                          maintenanceUntilDate &&
                          format(selectedDate, "yyyy-MM-dd") ===
                            format(maintenanceUntilDate, "yyyy-MM-dd");

                        const isBlocked =
                          room.is_under_maintenance &&
                          format(selectedDate, "yyyy-MM-dd") <=
                            format(fakeBlockedDate, "yyyy-MM-dd") &&
                          format(selectedDate, "yyyy-MM-dd") >=
                            format(fakeBlockedStartDate, "yyyy-MM-dd");

                        const isClickable =
                          bookingInfo.length === 0 &&
                          !isPastDate &&
                          !!room.room_no &&
                          !isBlocked && room?.occupancy_status_code != CHECK_IN;
                        return (
                          <TableCell
                            key={index}
                            sx={{
                              border: "0.5px solid #FCF0D4",
                              position: "relative",
                              padding: 0,
                              verticalAlign: "top",
                              cursor: isClickable ? "pointer" : "default",
                            }}
                            onClick={() =>
                              isClickable &&
                              handleCellClick({
                                room_key: room.room_key,
                                booking_status: room.booking_status,
                                selectedDate,
                              })
                            }
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                minHeight: {
                                  xs: "15.385vw",
                                  sm: "7.195vw",
                                  md: "4.167vw",
                                },
                              }}
                            >
                              {isBlocked ? (
                                <Box
                                  sx={getBlockedBarStyle(
                                    isOriginalBlockedRange
                                  )}
                                >
                                  <Typography
                                    sx={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      ml: "5%",
                                      fontSize: {
                                        xs: "2.31vw",
                                        sm: "1.439vw",
                                        md: "0.834vw",
                                      },
                                      fontWeight: 500,
                                      width: {
                                        xs: "15.051vw",
                                        sm: "9.832vw",
                                        md: "5.694vw",
                                      },
                                    }}
                                  >
                                    {format(selectedDate, "yyyy-MM-dd") ===
                                    format(maintenanceStartDate, "yyyy-MM-dd")
                                      ? t(
                                          "pages.home_page.room_view_section.blocked"
                                        )
                                      : ""}
                                  </Typography>
                                  {isOriginalBlockedEndDate && (
                                    <CustomTooltip
                                      placement="top"
                                      arrow
                                      sx={{
                                        "& .MuiTooltip-tooltip": {
                                          backgroundColor: "#FFFFFF",
                                          width: "fit-content",
                                          minWidth: "10vw",
                                          minHeight: "3vw",
                                          padding: "4%",
                                          borderRadius: "8px",
                                        },
                                      }}
                                      PopperProps={{
                                        modifiers: [
                                          {
                                            name: "preventOverflow",
                                            options: {
                                              boundary: "window",
                                            },
                                          },
                                        ],
                                      }}
                                      title={
                                        <>
                                          <Typography
                                            sx={{
                                              fontSize: {
                                                xs: "2.564vw",
                                                sm: "1.439vw",
                                                md: "0.834vw",
                                              },
                                              color: "#474747",
                                            }}
                                          >
                                            {room.maintenance_reason}
                                          </Typography>
                                        </>
                                      }
                                      open={activeTooltip === room.room_id}
                                    >
                                      <Info
                                        sx={{
                                          fontSize: {
                                            xs: "3.077vw",
                                            sm: "1.919vw",
                                            md: "1.112vw",
                                          },
                                          mr: "5%",
                                          color: "#666666",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleTooltipToggle(room.room_id)
                                        }
                                        onMouseEnter={() =>
                                          handleTooltipToggle(room.room_id)
                                        }
                                        onMouseLeave={handleTooltipClose}
                                      />
                                    </CustomTooltip>
                                  )}
                                </Box>
                              ) : (
                                ""
                              )}
                              {bookingInfo?.map((booking, bookingIndex) => {
                                const {
                                  fake_checkout,
                                  checkout,
                                  status,
                                  reservation_key,
                                  booking_id,
                                } = booking;

                                let isRealCheckout = true;
                                if (
                                  !!booking.fake_checkout &&
                                  checkout !== fake_checkout &&
                                  new Date(checkout) <= new Date(date)
                                ) {
                                  isRealCheckout = false;
                                }

                                return (
                                  <Box
                                    key={bookingIndex}
                                    sx={getEventBarStyle({
                                      status,
                                      reservation_key,
                                      booking_id,
                                      isRealCheckout,
                                    })}
                                    onClick={(event) =>
                                      isRealCheckout &&
                                      handleStatusBarClick(event, {
                                        ...booking,
                                        is_under_maintenance:
                                          room.is_under_maintenance,
                                      })
                                    }
                                    ref={(el) =>
                                      (barRefs.current[booking.booking_id] = el)
                                    }
                                  >
                                    {booking.isFirstVisibleCell &&
                                      isRealCheckout && (
                                        <Typography
                                          sx={{
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            ml: "5%",
                                            fontSize: {
                                              xs: "2.31vw",
                                              sm: "1.439vw",
                                              md: "0.834vw",
                                            },
                                            fontWeight: 500,
                                            width: {
                                              xs: "15.051vw",
                                              sm: "9.832vw",
                                              md: "5.694vw",
                                            },
                                          }}
                                        >
                                          {booking.customerName}
                                        </Typography>
                                      )}
                                  </Box>
                                );
                              })}
                            </Box>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Popover
        id={id}
        open={openpop}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "4px",
          },
        }}
      >
        <Box
          sx={{
            width: { xs: "40vw", sm: "23vw", md: "15.2vw" },
            padding: "7% 4%",
          }}
        >
          {optionsData
            .filter((option) => {
              const isReservedStatus = [CONFIRMED, TENTATIVE].includes(
                activeRowData?.booking_status
              );

              const isCheckInPastDate =
                activeRowData?.checkin &&
                new Date(activeRowData?.checkin).setHours(0, 0, 0, 0) <
                  new Date().setHours(0, 0, 0, 0);

              const isCheckInToday =
                activeRowData?.checkin &&
                new Date(activeRowData?.checkin).toDateString() ===
                  new Date().toDateString();

              const hideForRoomNo = !activeRowData?.room_no
                ? ["Room Shift"].includes(option.label)
                : ["Assign Room"].includes(option.label);

              if (isReservedStatus) {
                if (isCheckInToday) {
                  if (activeRowData?.is_under_maintenance) {
                    return (
                      !["Check In", "Check Out"].includes(option.label) &&
                      !hideForRoomNo
                    );
                  } else {
                    return (
                      !["Check Out"].includes(option.label) && !hideForRoomNo
                    );
                  }
                } else if (isCheckInPastDate) {
                  return ["Guest Details", "Cancel Reservation"].includes(
                    option.label
                  );
                } else {
                  return (
                    !["Check In", "Check Out"].includes(option.label) &&
                    !hideForRoomNo
                  );
                }
              } else {
                return ![
                  "Check In",
                  "Cancel Reservation",
                  "Assign Room",
                ].includes(option.label);
              }
            })
            .map((val, ind) => (
              <Button
                key={ind}
                fullWidth
                sx={{
                  paddingY: "4%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  textTransform: "none",
                  textWrap: "nowrap",
                }}
                onClick={() => {
                  val.action(ind);
                }}
                endIcon={
                  <Box
                    sx={{
                      width: { xs: "5.129vw", sm: "2.398vw", md: "1.389vw" },
                      height: { xs: "5.129vw", sm: "2.398vw", md: "1.389vw" },
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={val.icon}
                      alt=""
                      style={{ width: "100%", height: "100%" }}
                    />
                  </Box>
                }
              >
                <Typography
                  sx={{
                    fontSize: { xs: "2.821vw", sm: "1.679vw", md: "1.111vw" },
                    color: "#5A5A5A",
                  }}
                >
                  {t(
                    `pages.home_page.room_view_section.${val.label
                      .toLowerCase()
                      .replace(/ /g, "_")}`
                  )}
                </Typography>
              </Button>
            ))}
        </Box>
      </Popover>

      <Modal open={openModal} onClose={handleCloseModal} closeAfterTransition>
        <Fade in={openModal}>
          <Box
            sx={{
              minWidth: modalContent === "extendStay" && {
                xs: "60%",
                md: "35%",
              },
              borderRadius: "16px",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              bgcolor: "background.paper",
              padding: "1.5%",
              zIndex: 20,
            }}
          >
            {modalContent === "roomShift" && (
              <RoomShift
                bookingData={activeRowData}
                handleCloseModal={handleCloseModal}
                handleClosePopover={handleClosePopover}
                resetData={getAllBookings}
              />
            )}
            {modalContent === "extendStay" && (
              <ExtendStay
                bookingData={activeRowData}
                handleCloseModal={handleCloseModal}
                handleClosePopover={handleClosePopover}
                resetData={getAllBookings}
              />
            )}

            {modalContent === "cancelReservation" && (
              <CancelReservationModal
                bookingData={activeRowData}
                handleCloseModal={handleCloseModal}
                handleClosePopover={handleClosePopover}
                reset={getAllBookings}
              />
            )}
          </Box>
        </Fade>
      </Modal>
      <AssignRoomModal
        data={activeRowData}
        assignRoomAnchorEl={assignRoomAnchorEl}
        setAssignRoomAnchorEl={setAssignRoomAnchorEl}
        handleClosePopover={handleClosePopover}
        reset={getAllBookings}
      />
    </>
  );
};

export default WeekView;
