class EndPoints {
  BASE_URL = `${process.env.REACT_APP_BASE_URL}api/`;

  AUTH = {
    LOGIN: "auth/login",
    LOGOUT: "auth/logout",
    FORGET_PASSWORD: "auth/forgetpassword",
    HOTEL_REGISTRATION: "auth/hotelRegister",
    USER_REGISTRATION: "auth/userRegister",
    REFRESH_TOKEN: "/auth/refreshtoken",
  };

  USER = {
    GET_USER_BY_ROLE: "user/usersByRoleId",
    GET_ALL_USERS: "user/getAllUsers",
    UPDATE_USER: "/user",
    GET_USER_BY_KEY: "/user",
    GET_USER_BY_FILTER: "/user/getuserbyfilter",
  };
  HOTEL = {
    GET_ALL_HOTEL: "hotel",
  };

  ROLE = {
    GET_ALL_ROLES: "role",
  };

  FLOOR = {
    GET_ALL_FLOORS: "floor/getallfloorswithrooms",
  };

  BED = {
    GET_ALL_BED_TYPES: "bed",
    ADD_NEW_BED: "bed",
  };

  ROOM_TYPE = {
    ADD_ROOM_TYPE: "room/addRoomType",
    GET_ALL_ROOM_TYPE: "room/getAllRoomType",
    DELETE_ROOM_TYPE: "room/deleteroomtype",
    UPDATE_ROOM_TYPE: "/room/updateroomtype",
    GET_ROOM_TYPE_BY_KEY: "room/getroomtypebykey",
  };

  UTILS = {
    GET_ALL_AMENITIES: "utils/getallamenities",
    GET_ALL_STAY_TYPES: "utils/getallstaytypes",
    GET_ALL_COUNTRIES: "utils/country",
    GET_STATE: "/utils/state",
    GET_CITY: "/utils/city",
    GET_ALL_PROOF_TYPES: "utils/getallprooftypes",
  };

  ROOM = {
    ADD_OR_UPDATE_ROOMS: "room/addOrUpdateRooms",
    UPDATE_ROOM: "room/updateRoom",
    DELETE_ROOM: "room/deleteRoom",
    GET_AVAILABLE_ROOMS: "room/getavailablerooms",
    GET_AVAILABLE_ROOMS_TO_RESERVE: "room/getavailableroomstoreserve",
    GET_ROOMS_FOR_SHIFTING: "room/getRoomsForShifting",
    GET_ALL_ROOMS_BY_ROOMTYPE: "room/getallroomsbyroomtype",
    CHECK_OUT: "/booking/checkout",
    ROOM_SHIFT: "room/roomshift",
    RESERVATION_ROOM_SHIFT: "room/reservationroomshift",
    DELETE_BOOKING: "booking/deleteBooking",
    GET_ROOM_OCCUPANCY_BY_ROOM_TYPE: "room/getRoomOccupancyByRoomType",
    GET_ROOM_TYPE_BY_YEAR: "room/getRoomTypeByYear",
    UPDATE_ROOM_MAINTENANCE: "room/updateRoomMaintenance",
    GET_ROOM_BY_ROOM_KEY: "room/getroombyroomkey",
    GET_ROOM_HISTORY_BY_OCCUPANCY: "room/getroomhistorybyoccupancy",
    GET_ALL_ROOM_SHIFT_REASON: "room/getallroomshiftreason",
    GET_BOOKED_ROOMS_BY_FILTER: "room/getbookedroomsbyfilter",
    ASSIGN_ROOM_TO_RESERVE: "room/assignroomtoreserve",
  };

  ENQUIRY = {
    GET_ALL_ENQUIRY_SOURCES: "enquiry/getallenquirysource",
    GET_ENQUIRES_BY_MOBILE: "enquiry/getenquiriesbymobile",
  };

  RESERVATION = {
    CREATE_RESERVATION: "booking/createreservation",
    UPDATE_RESERVATION: "booking/updateallreservations",
    GET_ALL_RESERVATION: "booking/getallreservation",
    CANCEL_RESERVATION: "booking/cancelbookingorreservation",
    GET_ALL_RESERVATION_STATUS: "/booking/getallreservationstatus",
    EXTEND_RESERVATION: "booking/extendreservation",
  };

  GUESTINFO = {
    GET_ALL: "booking",
    GET_ALL_GUEST_HISTORY: "booking/getguesthistorybyfilter",
    GET_ALL_DRAFT: "/booking/getalldraftbyfilter",
  };

  CUSTOMER = {
    BLOCK_OR_UNBLOCK_CUSTOMER: "/customer/blockorunblockcustomer",
    GET_CUSTOMERINFO_BY_NUMBER: "/customer",
    GET_ALL_BLACKLIST: "/customer/getallblacklistbyfilter",
  };

  OCR = {
    SCAN_IMAGE: "utils/scanOcr",
  };

  BOOKING = {
    GET_DRAFT_BY_ID: "booking/draft",
    ADD_OR_UPDATE_DRAFT: "booking/addorupdatedraft",
    DELETE_DRAFT_BY_ID: "booking/draft",
    GET_ALL_CHECKIN_CHECKOUT: "booking/getallcheckincheckout",
    GET_ALL_CHECKOUT: "booking/getAllCheckOut",
    GET_ALL_EXPECTED_ARRIVAL: "booking/getallexpectedarrival",
    GET_ALL_EXPECTED_DEPARTURE: "booking/getallexpecteddeparture",
    RESTORE_BOOKING: "booking/restore",
    EXTEND_STAY: "booking/extendStay",
    GET_ALL_PENDING_DUES: "payment/getallpaymentdues",
    GET_ROOMBOOKING_BY_ROOMBOOKING_ID: "booking/roombooking",
    GET_ALL_BOOKING: "booking/getallbooking",
  };

  OCCUPANCY = {
    CREATE_OCCUPANCY: "occupancy/createoccupancy",
    UPDATE_ALL_OCCUPANCY: "occupancy/updatealloccupancies",
    CHECK_OUT_OCCUPANCIES: "occupancy/checkoutoccupancies",
    UPDATE_CHECK_OUT: "occupancy/updatecheckout",
    DELETE_OCCUPANCY: "occupancy/deleteoccupancybyfilter",
    EXTEND_OCCUPANCY: "occupancy/extendoccupancy",
    UNDO_DEPARTURE: "occupancy/undodeparture",
  };

  CLEANING_STATUS = {
    GET_ALL_CLEANING_STATUS: "status/getCleaningStatus",
  };

  HOUSE_KEEPING = {
    GET_ROOM_CLEANING_STATUS: "housekeeping/getroomcleaningstatus",
    CHANGE_ROOM_CLEANING_STATUS: "housekeeping/changeRoomCleaningStatus",
    GET_ALL_CLEANING_STATUS: "housekeeping/getAllCleaningStatus",
    MOVE_ROOMS_TO_DIRTY: "housekeeping/moveroomstodirty",
    GET_ALL_HOUSE_KEEPERS: "housekeeping/gethousekeepers",
    ADD_HOUSE_KEEPER: "housekeeping/newHouseKeeper",
    DELETE_HOUSE_KEEPER: "housekeeping/deleteHouseKeeper",
    UPDATE_HOUSE_KEEPER: "housekeeping/updateHouseKeeper",
  };

  STATUS = {
    GET_BOOKING_STATUS: "status/getBookingStatus",
    GET_ALL_CLEANING_STATUS: "status/getCleaningStatus",
    GET_ALL_CANCELLATION_REASON: "status/getallcancellationreason",
  };

  PAYMENT = {
    CREATE_PAYMENT: "payment/createpayment",
    GET_PAYMENT_HISTORY: "/payment/getpaymenthistorybybooking/",
    GET_PAYMENT_DETAIL_BY_BOOKING: "/payment/getpaymentdetailbybooking/",
    GET_ALL_PAYMENT_TYPE: "/payment/getAllPaymentType",
  };

  TAX = {
    CALCULATE_TAX: "tax/calculateTax",
    GET_PAYMENT_DETAIL: "tax/getPaymentDetailsByFilter",
    GET_TAX_RATE_SLABS: "tax/getalltaxrateslabs",
    UPDATE_ROOM_PRICE_AND_TAXES: "/tax/updateroompriceandtaxes",
  };

  INVOICE = {
    GET_INVOICE: "/invoice/getinvoicebyfilter",
    GET_INVOICE_BY_KEY: "invoice/getinvoicebykey",
    GET_ALL_INVOICE_TYPE: "invoice/getallinvoicetype",
  };

  ADDON = {
    GET_EXTRA_PERSON_HISTORY: "addon/getextrapersonhistorybyoccupancy",
    ADD_EXTRA_PERSON: "addon/addpersons",
    UPDATE_EXTRA_PERSON: "addon/updateAddons",
    DELETE_ADDON: "addon/deleteaddon",
  };

  SALES_REPORT = {
    GET_ALL: "report/getsalesreport",
  };

  GST_REPORT = {
    GET_ALL: "report/getgstreport",
  };

  HOUSEKEEPING_REPORT = {
    GET_ALL: "report/gethousekeepingreport",
  };

  NOTIFICATION = {
    GET_ALL: "notification/getallconfiguration",
    UPDATE: "notification/updatenotificationconfig",
  };
}

export const END_POINT = new EndPoints();
